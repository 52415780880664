exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/searchIndex.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-work-type-js": () => import("./../../../src/templates/workType.js" /* webpackChunkName: "component---src-templates-work-type-js" */)
}

